import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Foto from "./pages/Foto";

const App = ({ history }) => {
  const [stageClass, setStageClass] = useState("start");
  const [additionalClass, setAdditionalClass] = useState("");

  setTimeout(() => {
    setAdditionalClass("app--initialized");
  }, 2000);

  return (
    <div
      className={"stage " + stageClass + " " + additionalClass}
      tabIndex="-1"
    >
      <Router>
        <Switch>
          <Route path="/foto">
            <Foto setStageClass={setStageClass} />
          </Route>
          <Route>
            <Redirect to="/foto" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
