import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Link, useParams, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import "../styles/stage.scss";
import './Foto.scss';
import $ from "jquery";
import localStorageAvailable from "../util/localStorageUtility"
import Loading from "../components/Loading";
import FullImage from "../components/FullImage";

const Foto = (props) => {
  const [player, setPlayer] = useState(null);
  const [guest, setGuest] = useState(null);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
    $(".stage").animate(
      { scrollTop: 50 },
      500,
      "swing",
      () => {});
    }, 600);
    setErrors([]);
  }, [pathname]);

  useEffect(() => {
    props.setStageClass("light");
  }, []);

  useEffect(() => {
    if (localStorageAvailable()) {
      var playerId = localStorage.getItem("weddingFotoChallengePlayer");
      if (playerId) {
        setPlayer(Number(playerId));
        loadGuest(playerId);
      } 
    }
  }, []);

  var loadGuest = (playerId) => {
    $.ajax({
      method: "GET",
      url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/guest?id=" : "/api/guest?id=") + playerId,
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      setGuest(response);
    }).catch((error) => {
      
    });
  }

  var handleError = (msg) => {
    if (msg) {
      setErrors(errors => [
        ...errors,
        msg
      ]);
    }
  };

  var showPreview = (src, title) => {
    setPreview({src,title});
  };

  var closePreview = () => {
    setPreview(null);
  }
  
  return (
    <div className={'stage__content stage__content--foto'}>
      {errors && errors.length > 0 && 
        <div className="error__wrapper">
          {
            errors.map((e, i) => {
              return <div className="error__item" key={'name_' + i}>
                {e}
              </div>;
            })
          }
          <div className="error__close" onClick={() => {setErrors([]);}}>X</div>
        </div>
      }
      <Switch>
        <Route path={'/foto/gast'}>
          <div className="foto__navigation">
            <Link to={'/foto'} onClick={() => { setPlayer(null); }}>Info</Link>
          </div>
          <Guests onSelected={setPlayer} onGuestSelected={loadGuest} onError={handleError} onLoading={setLoading} />
        </Route>
        <Route exact path={'/foto/challenges'}>
          <div className="foto__navigation">
            <Link to={'/foto'} onClick={() => { setPlayer(null); }}>Info</Link>
            {guest && 
              <Link to={'/foto/challenges'}>{guest.nickname}</Link>
            }
          </div>
          <Challenges player={player} onError={handleError} onLoading={setLoading}/>
        </Route>
        <Route exact path={'/foto/alles'}>
          <div className="foto__navigation">
            <Link to={'/foto'} onClick={() => { setPlayer(null); }}>Info</Link>
            {guest && 
              <Link to={'/foto/challenges'}>{guest.nickname}</Link>
            }
          </div>
          <AllChallenges player={player} onError={handleError} onLoading={setLoading} onPreview={showPreview}/>
        </Route>
        <Route path={'/foto/challenges/:id'}>
          <div className="foto__navigation">
            <Link to={'/foto'} onClick={() => { setPlayer(null); }}>Info</Link>
            {guest && 
              <Link to={'/foto/challenges'}>{guest.nickname}</Link>
            }
            <a onClick={() => window.history.back()} className="flex-end">&lt; zurück</a>
          </div>
          <Challenge player={player} onError={handleError} onLoading={setLoading} loading={loading} onPreview={showPreview}/>
        </Route>
        <Route path={'/foto'}>
          <h1>Foto-Challenge</h1>
          <ChallengeIntro player={player}/>
        </Route>
      </Switch>
      {loading && 
        <Loading/>
      }
      {preview && 
        <FullImage src={preview.src} title={preview.title} close={closePreview} />
      }
    </div>
  );
};
export default Foto;

const ChallengeIntro = (props) => {
  let history = useHistory();
  let { player } = props;

  useEffect(() => {
    if (player) {
      history.replace("/foto/challenges");
    }
  }, [player])

  return <div>
    <div>
      <p>Schön, dass du an unserer Foto-Challenge teilnehmen möchtest. Nebst der Möglichkeit, bei diesem Spiel eine <i>Fujifilm Instax Mini 9</i> Sofortbildkamera zu gewinnen, hilfst du uns dabei, einige Momente unseres Festes für die Ewigkeit festzuhalten.</p>
      <p><b>Und so funktioniert's:</b> Wähle auf der nächsten Seite zuerst deinen Namen aus. Anschliessend werden dir initial zwei zufällige Aufgaben zugeteilt, welche du mit deinem Smartphone fotografieren musst. Solltest du mit einer Challenge nicht einverstanden sein, kannst du sie auch überspringen. Ist dir langweilig oder hast du Interesse, zusätzliche Challenges zu meistern, kannst du jederzeit weitere Aufgaben für dich anfordern. Pro Challenge kannst du ein Foto einreichen. Ausnahme: In der Kategorie "freies Bild" kannst du so viele Bilder aufnehmen/hochladen, wie du möchtest. Wir sind über alle Beiträge von dir dankbar!</p>
      <p><b>So gewinnst du:</b> Bei dieser Foto-Challenge gibt es die zwei "Fujifilm Instax Mini 9" von der Gästebuch-Ecke zu gewinnen. Gewonnen hat einerseits, wer die meisten Challenges gemeistert hat (bei Gleichstand entscheidet das Los), und andererseits, wer das beste Foto im Rahmen dieser Challenge hochgeladen hat (Entscheid des Brautpaares).</p>
      <Link to={'/foto/gast'} className="button">Los geht's</Link>
    </div>
  </div>
}

const Guests = (props) => {
  let history = useHistory();

  const [players, setPlayers] = useState([]);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    // Load challenge images
    loadGuests();
  }, []);

  var loadGuests = () => {
    if (players && players.length > 0) {
      return;
    }
    props.onLoading(true);
    $.ajax({
      method: "GET",
      url: window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/guests" : "/api/guests",
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      setPlayers(response);
      props.onLoading(false);
    }).catch((error) => {
      // TODO: Anonym fortfahren?
      props.onError("Die Gästeübersicht kann gerade nicht geladen werden.");
      props.onLoading(false);
    });
  }

  var guestSelected = (e, guest, confirmed) => {
    if (!guest) return;

    if (!confirmed) {
      setPlayer(guest);
    } else {
      if (localStorageAvailable()) {
        localStorage.setItem("weddingFotoChallengePlayer", guest.id);
      }

      props.onSelected(guest.id);
      props.onGuestSelected(guest.id);
      history.push("/foto/challenges");
    }
  }

  return <div>
    <p><b>Gast wählen:</b> Um mit der Challenge zu beginnen, wähle untenstehend deinen Namen aus.</p>
      {!player && 
          <ul className="guest__list">
            {players.map((p) => {
              return <li key={'guest_' + p.id} onClick={(e) => {guestSelected(e, p, false);}}>{p.nickname}</li>
            })}
          </ul>
      }
      {player && 
        <div className="guest__prompt">
          <span>Du spielst fortan als <b>{player.nickname}</b> und die von dir hochgeladenen Fotos werden mit diesem Namen gespeichert.</span>
          <button onClick={(e) => {guestSelected(e, player, true);}}>Fortfahren</button>
          <span className="guest__prompt__link" onClick={() => {setPlayer(null);}}>Zurück und anderen Gast wählen</span>
        </div>
      }
  </div>
}

const Challenges = (props) => {
  let history = useHistory();
  let { player } = props;

  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    if (player) {
      loadChallenges();
    }
  }, [player]);
  
  var loadChallenges = () => {
    if (challenges && challenges.length > 0) {
      return;
    }
    props.onLoading(true);

    $.ajax({
      method: "GET",
      url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/challenges" : "/api/challenges") + "?player=" + player,
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      setChallenges(response);
      props.onLoading(false);
    }).catch((error) => {
      props.onError("Die Challenges können gerade nicht geladen werden.");
      props.onLoading(false);
    });
  }

  var challengeSelected = (e, c) => {
    if (!c) return;
    history.push("/foto/challenges/" + c.id);
  }

  var addChallenge = () => {
    props.onLoading(true);
    $.ajax({
      method: "POST",
      url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/challenges" : "/api/challenges") + "?player=" + player,
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      props.onLoading(false);
      history.push("/foto/challenges/" + Number(response));
    }).catch((error) => {
      if (error.status === 406) {
        props.onError(error.responseText);
      } else {
        props.onError("Dir konnte keine neue Challenge zugewiesen werden.");
      }
      props.onLoading(false);
    });
  }

  return <div>
    <p><b>Deine Challenges:</b> Nachfolgende Aufgaben wurden für dich ausgewählt. Wähle aus, welche du erledigen möchtest, oder füge weitere Challenges hinzu.</p>
          <div className="challenge__list">
            {challenges.map((c) => {
              let className = "challenge__box " + (c.done ? "challenge__box--done" : "");
              return <div className={className} key={'challenge_' + c.id} onClick={(e) => {challengeSelected(e, c);}}><span className="challenge__title">{c.title}</span> <span className="challenge__description">{c.teaser}</span></div>
            })}
            <div className="challenge__box challenge__box--spontan" onClick={(e) => {history.push("/foto/challenges/free");}}><span className="challenge__title">Freies Bild</span> <span className="challenge__description">Unabhängig der Challenge - füge deine besten Bilder hier hinzu!</span></div>
            <div className="challenge__box challenge__box--add" onClick={(e) => {addChallenge();}}><span className="challenge__title">Challenge hinzufügen</span> <span className="challenge__description">Füge eine weitere Aufgabe hinzu</span></div>
          </div>

          <div>
            <Link className="challenge__view-all" to={'/foto/alles'}>Alle Fotos, alle Challenges und Leaderboard anschauen</Link>
            <Link className="challenge__change-user" to={'/foto/gast'}>Benutzer wechseln</Link>
          </div>
  </div>
}

const AllChallenges = (props) => {
  let { player } = props;

  const [challenges, setChallenges] = useState([]);
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    if (player) {
      loadChallenges();
      loadLeaders();
    }
  }, [player]);
  
  var loadChallenges = () => {
    if (challenges && challenges.length > 0) {
      return;
    }

    props.onLoading(true);
    $.ajax({
      method: "GET",
      url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/allchallenges" : "/api/allchallenges") + "?player=" + player,
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      var challengeImages = [];
      for(var i = 0; i < response.length; i++) {
        let ci = response[i];
        if (challengeImages.length === 0 || challengeImages[challengeImages.length - 1].id !== ci.id) {
          challengeImages.push({id: ci.id, title: ci.title, teaser: ci.teaser, photos: []});
        }
        if (ci.url) {
          challengeImages[challengeImages.length - 1].photos.push({ url: ci.url, nickname: ci.nickname });
        }
      }
      
      setChallenges(challengeImages);
      props.onLoading(false);
    }).catch((error) => {
      props.onError("Die Challenge-Übersicht kann gerade nicht geladen werden.");
      props.onLoading(false);
    });
  }

  var loadLeaders = () => {
    if (leaders && leaders.length > 0) {
      return;
    }
    
    $.ajax({
      method: "GET",
      url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/leaders" : "/api/leaders"),
      crossDomain: true,
      contentType: "application/json; charset=utf-8"
    }).then((response) => {
      var leadersWithPhotos = response.filter((p) => { return p.count > 0 || p.id == player; });
      setLeaders(leadersWithPhotos);
    }).catch((error) => {
      props.onError("Das Leaderboard kann gerade nicht geladen werden.");
    });
  }

  var list = () => {
    let content = [];
    for (var k = 0; k < challenges.length; k++) {
      let c = challenges[k];
      content.push(<div className="challenge__full__list__item" key={'challenge_' + c.id}>
          <b>{c.title}</b>
          {(c.photos && c.photos.length > 0) && 
          <div className="challenge__picture__wrapper">
          {c.photos.map((p, i) => {
              return <div className="challenge__picture" key={'challenge_photo_' + p.url}>
                  <div>
                    <img src={p.url + (i === 0 ? '?preview' : '?small')} alt={c.title + " von " + p.nickname} onClick={() => { props.onPreview(p.url, c.title + " von " + p.nickname); }} />
                    <i>{"von " + p.nickname}</i>
                  </div>
                </div>
            })}
            </div>
            }
            {(!c.photos || c.photos.length === 0) && 
              <i>Zu dieser Challenge wurde noch kein Foto eingereicht.</i>
            }
            <Link className="challenge__accept" to={'/foto/challenges/'+ c.id}>Aufgabe ansehen</Link>
        </div>)
    }
    return content;
  }

  return <div>
    <h4>Alle Challenges - Alle Fotos</h4>
    <b>Leaderboard:</b>
    <ol className="leader__list">
      {leaders.map((l) => {
        return <li key={'leader_' + l.id} className={l.count == 0 ? "empty" : ""}><b>{l.nickname}</b> mit {l.piccount} Foto{l.piccount == 1 ? "" : "s"} in <b>{l.count} Challenge{l.count == 1 ? "" : "s"}</b></li>
        })
      }
    </ol>
          <div className="challenge__full__list">
            {list()}
          </div>
  </div>
}

const Challenge = (props) => {
  let { id } = useParams();
  let { player } = props;
  
  const fileInputField = useRef(null);
  const [challenge, setChallenge] = useState(null);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (challenge || props.loading) {
      return;
    }
    if (player && (id)) {
      loadChallenge();
    }
  }, [player, id]);

  var loadChallenge = () => {
    props.onLoading(true);
      
      $.ajax({
        method: "GET",
        url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/challenge" : "/api/challenge") + "?player=" + player + "&challenge=" + id,
        crossDomain: true,
        contentType: "application/json; charset=utf-8"
      }).then((response) => {
        if (response) {
          setChallenge(response.challenge);
          setPhotos(response.photos);
        }
        props.onLoading(false);
      }).catch((error) => {
        props.onLoading(false);
        if (error.status === 404) {
          props.onError("Die Challenge konnte nicht gefunden werden. Gehe zurück zur Übersicht.");
        } else {
          props.onError("Die Challenge konnte nicht geladen werden geladen werden.");
        }
      });
  }

  var onChangeHandler = event => {
    props.onLoading(true);

    var formData = new FormData();
    formData.append("file", event.target.files[0]);
    
    var url = (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/photo" : "/api/photo") + "?player=" + player + "&challenge=" + challenge.id;
    if (challenge.title === "Diverse Fotos") {
      url += "&free=1";
    }

    $.ajax({
      method: "POST",
      url: url,
      crossDomain: true,
      contentType: false,
      processData: false,
      data: formData
    }).then((response) => {
      setChallenge(null);
      props.onLoading(false);
      event.target.value = null;
      loadChallenge();
    }).catch((error) => {
      props.onLoading(false);
      event.target.value = null;
      props.onError("Dein Bild konnte nicht hochgeladen werden. Versuche es bitte nochmals.");
    });
  }

  return <form onSubmit={undefined}>
    {challenge && 
      <div>
        <p><b>{challenge.title}:</b> {challenge.teaser}</p>
        <p>{challenge.description}</p>
        {challenge.done && 
          <div className="challenge__picture">
            <img src={challenge.img + '?preview'} alt={challenge.title + " von " + challenge.nickname} onClick={() => { props.onPreview(challenge.img, challenge.title + " von " + challenge.nickname); }} />
          </div>
        }
        {player && 
          <div className={"challenge__dropzone " + (challenge.done ? "challenge__dropzone--small" : "")}>
            {!challenge.done && 
              <div className="challenge__dropzone__label">Foto aufnehmen/hochladen</div>
            }
            {challenge.done && 
              <div className="challenge__dropzone__label">{challenge.title === "Diverse Fotos" ? "Weiteres Foto aufnehmen/hochladen" : "Anderes Foto aufnehmen/hochladen"}</div>
            }
            <input type="file" name="file" accept="image/*" ref={fileInputField} onChange={onChangeHandler}/>
          </div>
        }
        {photos && photos.length > 0 &&
          <div className="challenge__more">
            <b>Weitere Fotos zu dieser Challenge:</b>
            {photos.map((p) => {
              return <div className="challenge__picture" key={'challenge_' + p.url}>
                  <img src={p.url + '?preview'} alt={challenge.title + " von " + p.nickname} onClick={() => { props.onPreview(p.url, challenge.title + " von " + p.nickname); }}  />
                  <i>{"von " + p.nickname}</i>
                </div>
            })}
          </div>
        }
      </div>
    }
  </form>
}
